import React, { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';
import { TourCity, TourState, Scenario, City, Country } from '../types'; // Importing the types used in the context
import { calculateDistance } from '../lib/distance';

// Define the type for the context value
interface TourContextType {
  state: TourState;
  dispatch: React.Dispatch<TourAction>;
}

// Define the actions available in the context
export type TourAction =
  | { type: 'SET_DATE_RANGE'; payload: { start: Date | null; end: Date | null } }
  | { type: 'ADD_COUNTRY'; payload: Country }
  | { type: 'REMOVE_COUNTRY'; country_code: string }
  | { type: 'SET_DAYS_OFF_FIXED'; payload: Date[] }
  | { type: 'SET_DAYS_OFF_PREFERRED'; payload: Date[] }
  | { type: 'ADD_CITY'; payload: TourCity }
  | { type: 'REMOVE_CITY'; city_qid: string }
  | { type: 'UPDATE_CITY'; payload: TourCity }
  | { type: 'SET_MAX_DISTANCE'; payload: number }
  | { type: 'SET_MAX_DAYS'; payload: number }
  | { type: 'SET_PROJECT_TITLE'; payload: string }
  | { type: 'SET_START_CITY'; payload: City | null }
  | { type: 'ADD_SCENARIO'; payload: Scenario }
  | { type: 'REMOVE_SCENARIO'; id: string }
  | { type: 'COPY_SCENARIO'; id: string }
  | { type: 'UPDATE_SCENARIO'; payload: Scenario }
  | { type: 'TOGGLE_FAVORITE_SCENARIO'; id: string }
  | { type: 'SET_STATUS'; payload: 'idle' | 'loading' | 'ready' | 'error' | 'timeout' | 'offline' };

// Create the context with a default value
const TourContext = createContext<TourContextType | undefined>(undefined);

// Define the reducer to handle actions
const tourReducer = (state: TourState, action: TourAction): TourState => {
  const updateDistanceMatrix = (cities: TourCity[], existingMatrix = {}) => {
    const newMatrix: { [cityQid: string]: { [cityQid: string]: number } } = {};
  
    cities.forEach(city1 => {
      newMatrix[city1.city.city_qid] = {};
      cities.forEach(city2 => {
        if (city1.city.city_qid !== city2.city.city_qid) {
          newMatrix[city1.city.city_qid][city2.city.city_qid] = calculateDistance(
            city1.city.latitude!,
            city1.city.longitude!,
            city2.city.latitude!,
            city2.city.longitude!
          );
        }
      });
    });
  
    return { ...existingMatrix, ...newMatrix };
  };

  switch (action.type) {
    case 'SET_DATE_RANGE':
      return { ...state, dateRange: action.payload || { start: null, end: null } }; 
    case 'ADD_COUNTRY':
      const countryExists = state.countries.some(country => country.country_code === action.payload.country_code);
      if (!countryExists) {
        return { ...state, countries: [...state.countries, action.payload] };
      } else {
        console.log("Country already added.");
        return state;
      }
    case 'REMOVE_COUNTRY':
      return { ...state, countries: state.countries.filter(country => country.country_code !== action.country_code) };
    case 'SET_DAYS_OFF_FIXED':
      return { ...state, days_off_fixed: action.payload };
    case 'SET_DAYS_OFF_PREFERRED':
      return { ...state, days_off_preferred: action.payload };
    case 'ADD_CITY':
      const cityExists = state.cities.some(city => city.city.city_qid === action.payload.city.city_qid);
      if (!cityExists) {
        const newCities = [...state.cities, action.payload];
        return {
          ...state,
          cities: newCities,
          distanceMatrix: updateDistanceMatrix(newCities, state.distanceMatrix),
        };
      } else {
        console.log("City already added.");
        return state;
      }
    case 'REMOVE_CITY':
      const remainingCities = state.cities.filter(city => city.city.city_qid !== action.city_qid);
      return {
        ...state,
        cities: remainingCities,
        distanceMatrix: updateDistanceMatrix(remainingCities, state.distanceMatrix),
      };
    case 'UPDATE_CITY':
      const updatedCities = state.cities.map(city =>
        city.city.city_qid === action.payload.city.city_qid ? action.payload : city
      );
      return {
        ...state,
        cities: updatedCities,
        distanceMatrix: updateDistanceMatrix(updatedCities, state.distanceMatrix),
      };
    case 'SET_MAX_DISTANCE':
      return { ...state, maxDistancePerDay: action.payload };
    case 'SET_MAX_DAYS':
      return { ...state, maxConsecutiveDays: action.payload };
    case 'SET_START_CITY':
      return { ...state, startCity: action.payload };
    case 'SET_PROJECT_TITLE':
      return { ...state, projectTitle: action.payload };
    case 'ADD_SCENARIO':
      return { ...state, plans: [...state.plans, action.payload] };
    case 'UPDATE_SCENARIO':
      return {
        ...state,
        plans: state.plans.map(plan => {
          if (plan.id === action.payload.id) {
            const updatedPlan = {
              ...plan,
              plans: action.payload.plans.sort((a, b) => a.date.getTime() - b.date.getTime())
            };
            return updatedPlan;
          }
          return plan;
        })
    }; 
    case 'COPY_SCENARIO':
      const planToCopy = state.plans.find(plan => plan.id === action.id);
      if (planToCopy) {
        const copiedPlan = {
          ...planToCopy,
          id: `${planToCopy.id}-copy-${Date.now()}`,
          creation: new Date()
        };
        return { ...state, plans: [...state.plans, copiedPlan] };
      }
      return state;
    case 'REMOVE_SCENARIO':
      return { ...state, plans: state.plans.filter(plan => plan.id !== action.id) };
    case 'TOGGLE_FAVORITE_SCENARIO':
      return {
        ...state,
        plans: state.plans.map(plan =>
          plan.id === action.id ? { ...plan, fav: !plan.fav } : plan
        )
      };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }  
};

// Function to parse dates in the state
const parseDates = (state: TourState): TourState => {
  const parseDate = (date: any): Date | null => {
    if (date === null) return null; // Handle null explicitly
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  };

  const filterValidDates = (dates: (Date | null)[]): Date[] => {
    return dates.filter((d): d is Date => d !== null);
  };

  return {
    ...state,
    dateRange: {
      start: parseDate(state.dateRange.start),
      end: parseDate(state.dateRange.end),
    },
    cities: state.cities.map(city => ({
      ...city,
      venue_dates: city.venue_dates.map(venue => ({
        ...venue,
        date_fixed: filterValidDates(venue.date_fixed.map(parseDate)),
        date_first_option: filterValidDates(venue.date_first_option.map(parseDate)),
        date_second_option: filterValidDates(venue.date_second_option.map(parseDate)),
        date_third_option: filterValidDates(venue.date_third_option.map(parseDate)),
      })),
    })),
    plans: state.plans.map(plan => ({
      ...plan,
      plans: plan.plans
        .map(p => ({
          ...p,
          date: parseDate(p.date),
        }))
        .filter(p => p.date !== null) as { date: Date; city: City }[],
      creation: parseDate(plan.creation) || new Date(), // Default to current date if null
    })),
  };
};

// Define the provider component
interface TourProviderProps {
  children: ReactNode;
}

const initialState: TourState = {
  projectTitle: '',
  dateRange: { start: null, end: null },
  countries: [
    { country_code: 'de', country: 'Germany' }
  ],  // Ensure countries is initialized
  cities: [],
  plans: [],
  days_off_fixed: [],
  days_off_preferred: [],
  maxDistancePerDay: 400,
  maxConsecutiveDays: 7,
  startCity: null,
  status: 'idle',
  distanceMatrix: {}
};

export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(tourReducer, initialState, (initial) => {
    const storedState = localStorage.getItem('tourState');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      return { ...initial, ...parseDates(parsedState) };
    }
    return initial;
  });

  useEffect(() => {
    localStorage.setItem('tourState', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    dispatch({ type: 'SET_STATUS', payload: 'idle' });
  }, []);

  return (
    <TourContext.Provider value={{ state, dispatch }}>
      {children}
    </TourContext.Provider>
  );
};

// Custom hook to use the tour context
export const useTour = () => {
  const context = useContext(TourContext);
  if (context === undefined) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};

import React from 'react';
import { useTour } from '../context/TourContext';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Toggle } from "./ui/toggle";
import { TourDatePicker } from './TourDatePicker';
import { TourCity, VenueDates } from '../types';
import { Factory as FactoryIcon, Trash as TrashIcon, Settings2 as SettingsIcon, Heart as HeartIcon } from 'lucide-react';

export function TourDateTable() {
  const { state, dispatch } = useTour();
  const multipleCountries = state.countries.length > 1;

  const handlePriorityChange = (city_qid: string, priority: boolean) => {
    const city = state.cities.find(c => c.city.city_qid === city_qid) || {} as TourCity;
    const updatedCity = { ...city, priority };
    if (city.city) {
      dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
    }
  };

  const handleIterationChange = (city_qid: string, iterations: number) => {
    const city = state.cities.find(c => c.city.city_qid === city_qid) || {} as TourCity;
    const updatedCity = { ...city, iterations };
    if (city.city) {
      dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
    }
  };

  const handleVenueDateChange = (city_qid: string, venue_qid: string, dateType: keyof VenueDates, dates: Date[]) => {
    const city = state.cities.find(c => c.city.city_qid === city_qid) || {} as TourCity;
    const venue = city.venue_dates.find(v => v.venue_qid === venue_qid);
    if (venue) {
      const updatedVenue = { ...venue, [dateType]: dates };
      const updatedCity = {
        ...city,
        venue_dates: city.venue_dates.map(v => v.venue_qid === venue_qid ? updatedVenue : v)
      };
      if (city.city) {
        dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
      }
    }
  };

  const handleVenueNameChange = (city_qid: string, venue_qid: string, venueName: string) => {
    const city = state.cities.find(c => c.city.city_qid === city_qid) || {} as TourCity;
    const venue = city.venue_dates.find(v => v.venue_qid === venue_qid);
    if (venue) {
      const updatedVenue = { ...venue, venue: venueName };
      const updatedCity = {
        ...city,
        venue_dates: city.venue_dates.map(v => v.venue_qid === venue_qid ? updatedVenue : v)
      };
      if (city.city) {
        dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
      }
    }
  };

  const handleAddVenue = (city_qid: string) => {
    const city = state.cities.find(c => c.city.city_qid === city_qid) || {} as TourCity;
    const newVenueNumber = city.venue_dates.length + 1;
    const newVenue: VenueDates = {
      venue_qid: `venue-${Date.now()}`,
      venue: `Venue ${newVenueNumber}`,
      date_fixed: [],
      date_first_option: [],
      date_second_option: [],
      date_third_option: [],
    };
    const updatedCity = { ...city, venue_dates: [...city.venue_dates, newVenue] };
    if (city.city) {
      dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
    }
  };

  const handleRemoveVenue = (city_qid: string, venue_qid: string) => {
    const city = state.cities.find(c => c.city.city_qid === city_qid) || {} as TourCity;
    const updatedCity = { ...city, venue_dates: city.venue_dates.filter(v => v.venue_qid !== venue_qid) };
    if (city.city) {
      dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
    }
  };

  const handleRemoveCity = (city_qid: string) => {
    dispatch({ type: 'REMOVE_CITY', city_qid });
  };

  const dateKeys: (keyof VenueDates)[] = ['date_fixed', 'date_first_option', 'date_second_option', 'date_third_option'];

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className='w-1/5'>Stadt</TableHead>
          <TableHead>Priorität</TableHead>
          <TableHead>Anzahl</TableHead>
          <TableHead>Feste Termine</TableHead>
          <TableHead>1. Option</TableHead>
          <TableHead>2. Option</TableHead>
          <TableHead>3. Option</TableHead>
          <TableHead><SettingsIcon aria-label="Settings" className="h-4 w-4" /></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {state.cities.map((tourCity) => (
          <React.Fragment key={tourCity.city.city_qid}>
            <TableRow>
              <TableCell>
                <div className="flex justify-between items-center">
                  {`${tourCity.city.city} ${multipleCountries ? ` (${tourCity.city.country_code})` : ''}`}
                  <Button variant="outline" onClick={() => handleAddVenue(tourCity.city.city_qid)} className="ml-2" aria-label="Add venue">
                    <FactoryIcon className="h-4 w-4" />
                  </Button>
                </div>
              </TableCell>
              <TableCell>
                <Toggle
                  variant="outline"
                  pressed={tourCity.priority}
                  className={`${tourCity.priority ? '!text-red-500 !hover:text-red-500' : ''}`}
                  onPressedChange={() => handlePriorityChange(tourCity.city.city_qid, !tourCity.priority)}
                  aria-label={`Toggle Priorität für ${tourCity.city.city}`}
                >
                  <HeartIcon className="h-4 w-4" />
                </Toggle>
              </TableCell>
              <TableCell>
                <Input
                  type="number"
                  min="1"
                  max="10"
                  className="w-20"
                  value={tourCity.iterations.toString()}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    handleIterationChange(tourCity.city.city_qid, newValue || 1);
                  }}
                />
              </TableCell>
              {tourCity.venue_dates.length === 1
                ? dateKeys.map(dateType => (
                  <TableCell key={dateType}>
                    <TourDatePicker
                      selectedDates={tourCity.venue_dates[0][dateType] as Date[]}
                      onChange={(dates) => handleVenueDateChange(tourCity.city.city_qid, tourCity.venue_dates[0].venue_qid, dateType, dates || [])}
                    />
                  </TableCell>
                ))
                : dateKeys.map(dateType => (
                  <TableCell key={dateType}></TableCell>
                ))
              }
              <TableCell className='text-right'>
                <Button
                  variant="outline"
                  aria-label={`Remove ${tourCity.city.city}`}
                  onClick={() => handleRemoveCity(tourCity.city.city_qid)}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </TableCell>
            </TableRow>
            {tourCity.venue_dates.length > 1 && tourCity.venue_dates.map((venue, index) => (
              <TableRow key={venue.venue_qid} className="bg-gray-100 dark:bg-zinc-900">
                <TableCell>
                  <Input
                    type="text"
                    placeholder={`Venue ${index + 1}`}
                    value={venue.venue}
                    onChange={(e) => handleVenueNameChange(tourCity.city.city_qid, venue.venue_qid, e.target.value)}
                  />
                </TableCell>
                <TableCell colSpan={2}></TableCell>
                {dateKeys.map(dateType => (
                  <TableCell key={dateType}>
                    <TourDatePicker
                      selectedDates={venue[dateType] as Date[]}
                      onChange={(dates) => handleVenueDateChange(tourCity.city.city_qid, venue.venue_qid, dateType, dates || [])}
                    />
                  </TableCell>
                ))}
                <TableCell>
                  <Button
                    variant="outline"
                    aria-label={`Remove venue ${venue.venue}`}
                    onClick={() => handleRemoveVenue(tourCity.city.city_qid, venue.venue_qid)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}

import { useTour } from '../context/TourContext';
import { TourDatePicker } from './TourDatePicker';
import { Label } from "./ui/label";

import { CalendarX2 as DaysOffFixedIcon, CalendarMinus as DaysOffPreferredIcon } from 'lucide-react';



export function TourDayOffOptions({ className }: { className?: string; }) {
  const { state, dispatch } = useTour();

  const handleDaysOffFixedChange = (dates: Date[] | undefined) => {
    if (dates) {
      dispatch({ type: 'SET_DAYS_OFF_FIXED', payload: dates });
    }
  };

  const handleDaysOffPreferredChange = (dates: Date[] | undefined) => {
    if (dates) {
      dispatch({ type: 'SET_DAYS_OFF_PREFERRED', payload: dates });
    }
  };


  return (
    <div  className={`grid gap-3 grid-cols-2 md:grid-cols-3 ${className}`}>
      <div className="flex flex-row items-center justify-between rounded-lg border p-4">
        <div>
          <DaysOffFixedIcon className="h-4 bg w-4 inline me-1.5" />
          <Label htmlFor="days_off_fixed">Fixe Day Off</Label>
          <p className='text-sm text-muted-foreground'>Tour-Tage, an denen keine Veranstaltungen stattfinden können.</p>
        </div>
        <TourDatePicker
          id="days_off_fixed"
          className="md:w-1/3 ms-2"
          selectedDates={state.days_off_fixed}
          onChange={handleDaysOffFixedChange}
        />
      </div>

      <div className="flex flex-row items-center justify-between rounded-lg border p-4">
        <div>
          <DaysOffPreferredIcon className="h-4 bg w-4 inline me-1.5" />
          <Label htmlFor="days_off_preferred" >Präferierte Day Off</Label>
          <p className='text-sm text-muted-foreground'>Tour-Tage auf die für die Terminierung eines Day Offs präferiert werden.</p>
        </div>
        <TourDatePicker
          id="days_off_preferred"
          className="md:w-1/3 ms-2"
          selectedDates={state.days_off_preferred}
          onChange={handleDaysOffPreferredChange}
        />
      </div>
    </div>

    
  );
}

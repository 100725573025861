import React, { useState } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { Command, CommandGroup, CommandEmpty, CommandInput, CommandItem } from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { ScrollArea } from "./ui/scroll-area"
import { Button } from './ui/button';
import { useTour } from '@/context/TourContext';  // Update to use the new context
import { City } from '@/types';
import { MapPin as MapPinIcon } from 'lucide-react';

interface TourAddCityProps {
  possible_cities: City[];
  usageType: string;
}

export function TourAddCity({ possible_cities, usageType }: TourAddCityProps) {
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const { state, dispatch } = useTour();  // Using new context
  const placeholderText = usageType === 'SET_START_CITY' ? "Stadt auswählen" : "Stadt hinzufügen";

  const multipleCountries = state.countries.length > 1;

  const handleSelectCity = (city_qid: string) => {
    const city = possible_cities.find(c => c.city_qid === city_qid);
    if (city) {
      if (usageType === 'SET_START_CITY') {
        dispatch({ 
          type: 'SET_START_CITY', 
          payload: {
            city: city.city,
            city_qid: city.city_qid,
            country_code: city.country_code,
            latitude: city.latitude,
            longitude: city.longitude
          }
        });
        setSelectedCity(city.city_qid);
      }
      if (usageType === 'ADD_CITY') {
        dispatch({
          type: 'ADD_CITY',  // Assuming the action type to add a city in the new context is 'ADD_CITY'
          payload: {
            city: city,
            venue_dates: [{
              venue_qid: "",
              venue: "",
              date_fixed: [],
              date_first_option: [],
              date_second_option: [],
              date_third_option: [],
            }],
            iterations: 1,
            priority: false
          }
        });
        setSelectedCity("");
      }
      setOpen(false);
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-full max-w-md mt-2">
          <MapPinIcon className="me-3 w-5 h-5" /> {selectedCity ? 
            `${possible_cities.find((c: City) => c.city_qid === selectedCity)?.city}${multipleCountries ? ` (${possible_cities.find((c: City) => c.city_qid === selectedCity)?.country_code})` : ''}` 
            : 
            placeholderText}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full max-w-md p-0">
        <Command>
          <CommandInput placeholder="Stadt suchen" />
          <CommandEmpty>Stadt nicht gefunden</CommandEmpty>
          <CommandGroup>
            <ScrollArea className="h-40">
              {possible_cities.map((city) => (
                <CommandItem
                  key={city.city_qid}
                  value={`${city.city}${multipleCountries ? ` (${city.country_code})` : ''}`}
                  onSelect={() => handleSelectCity(city.city_qid)}
                >
                  <Check className={selectedCity === city.city_qid ? "mr-2 h-4 w-4 opacity-100" : "mr-2 h-4 w-4 opacity-0"} />
                  {city.city}{multipleCountries ? ` (${city.country_code})` : ''}
                </CommandItem>
              ))}
            </ScrollArea>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

import React from 'react';
import { useTour } from '../context/TourContext';
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { addDays, format } from 'date-fns';
import { normalizeDate } from '../lib/utils';

import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

export function TourRange() {
    const { state, dispatch } = useTour();

    // Function to handle date selection
    const handleDateSelection = (range: DateRange | undefined) => {
        if (range && range.from && range.to) {
            dispatch({ type: 'SET_DATE_RANGE', payload: { start: normalizeDate(range.from), end: normalizeDate(range.to) } });
        }
        else if (range && range.from) {
            dispatch({ type: 'SET_DATE_RANGE', payload: { start: normalizeDate(range.from), end: null } });
        }
    };

    return (
        <div className="grid gap-2 mt-2">
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant="outline"
                        className="w-100 justify-center text-left font-normal"
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {state.dateRange.start && state.dateRange.end ? (
                            `${format(state.dateRange.start, "dd.MM.yy")} - ${format(state.dateRange.end, "dd.MM.yy")}`
                        ) : (
                            <span>Daten auswählen</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        ISOWeek
                        mode="range"
                        defaultMonth={state.dateRange.start || addDays(new Date(), 180)}
                        selected={{ from: state.dateRange.start || undefined, to: state.dateRange.end || undefined }}
                        onSelect={handleDateSelection}
                        numberOfMonths={2}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}

import { useTour } from '../context/TourContext';
import { Label } from "./ui/label";
import { Input } from "./ui/input";

import { ListX as MaxTourIcon, Ruler as MaxDistanceIcon } from 'lucide-react';

export function TourVariables({ className }: { className?: string; }) {
  const { state, dispatch } = useTour();

  const handleMaxDaysChange = (value: string) => {
    const newValue = parseInt(value, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch({ type: 'SET_MAX_DAYS', payload: newValue });
    }
  };

  const handleMaxDistanceChange = (value: string) => {
    const newValue = parseInt(value, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch({ type: 'SET_MAX_DISTANCE', payload: newValue });
    }
  };


  return (
    <div>
        <div  className={`grid gap-4 grid-cols-1 md:grid-cols-3 ${className}`}>
          <div className="space-y-2 flex flex-row items-center justify-between rounded-lg border p-4">
                <div>
                <MaxDistanceIcon className="h-5 w-5 me-1 inline" />
                <Label htmlFor="max_distance_per_day" >Maximale Tagesdistanz</Label>
                <p className='text-sm text-muted-foreground'>Maximale Distanz zwischen hintereinanderliegenden Shows in km.* </p>
                </div>
                <Input type="number" className="mt-2 w-24" id="max_distance_per_day" value={state.maxDistancePerDay.toString()}
                        onChange={(e) => handleMaxDistanceChange(e.target.value)} />
            </div>

            <div className="space-y-2 flex flex-row items-center justify-between rounded-lg border p-4">
                <div>
                <MaxTourIcon className="h-5 w-5 me-1 inline" />
                <Label htmlFor="max_consecutive_days" >Max. konsekutive Shows</Label>
                <p className='text-sm text-muted-foreground'>Höchstzahl aufeinanderfolgender Show-Tage bevor ein Day off notwendig ist.</p>
                </div>
                <Input type="number" className="mt-2 w-24" id="max_consecutive_days" value={state.maxConsecutiveDays.toString()}
                        onChange={(e) => handleMaxDaysChange(e.target.value)} />
            </div>

            <div className="space-y-2 flex flex-row items-center justify-between rounded-lg border p-4">
                <div>
                <Label htmlFor="more">Weitere Variablen</Label>
                <p className='text-sm text-muted-foreground'>Zukünftig können die verschiedenen Faktoren hinsichtlich ihrer Relevanz gewichtet werden.</p>
                </div>
            </div>
        </div>
        <p className='text-xs text-gray-600 mt-3'>* Alle derzeit in tourdice angegeben Distanzen sind die jeweilige Luftlinie - nicht die Fahrtstrecke. Faustregel: Fahrtstrecke = 1.2 * Luftlinie</p>
    </div>
    
  );
}

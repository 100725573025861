import { useTour } from '../context/TourContext';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { Scenario, ScenarioDateData } from '../types';
import { normalizeDate } from '../lib/utils';
import { deleteScenario } from '../lib/tourHelpers';
import { Button } from './ui/button';
import { Copy as CopyIcon, Trash as TrashIcon, Heart as HeartIcon, Download as DownloadIcon, MoreHorizontal, Map as MapIcon } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export function ScenarioActions({ plan, timelineData, className }: { plan: Scenario, timelineData: { [key: string]: ScenarioDateData }, className?: string; }) {
  const { state, dispatch } = useTour();

  const handleCopyPlan = (id: string) => {
    dispatch({ type: 'COPY_SCENARIO', id });
  };

  const handleRemovePlan = (id: string) => {
    deleteScenario(id, dispatch);
  };

  const handleExportPlan = (plan: Scenario, timelineData: { [key: string]: ScenarioDateData }) => {
    const csvContent = [
      ['Date', 'City', 'Country', 'Distance', 'Type'],
      ...Object.values(timelineData).map(({ date, city, distance, option }) => {
        return [
          format(normalizeDate(date), 'yyyy-MM-dd'),
          city?.city || '',
          city?.country_code || '',
          distance > 0 ? `${distance} km` : '-',
          option
        ];
      })
    ].map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const truncatedPlanId = plan.id.substring(0, 10);
    const formattedDate = format(new Date(), 'yyMMdd');
    const projectTitle = state.projectTitle.replace(/\s+/g, '_');

    saveAs(blob, `${formattedDate}_${projectTitle}-${truncatedPlanId}.csv`);
  };

  const handleToggleFavorite = (id: string) => {
    dispatch({ type: 'TOGGLE_FAVORITE_SCENARIO', id });
  };

  const handleViewInGoogleMaps = () => {
    const cityNames = Object.values(timelineData).map(({ city }) => {
      return city?.city;
    }).filter(Boolean);

    const origin = state.startCity?.city;
    const destination = cityNames[cityNames.length - 1];
    const waypoints = cityNames.slice(1, -1).join('|');

    const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints}`;
    window.open(mapsUrl, '_blank');
  };

  return (
    <div className={`flex justify-end ms-5 ${className ? className : ""}`}>
      <Button
        variant="outline"
        onClick={() => handleToggleFavorite(plan.id)}
        aria-label={plan.fav ? `Unfavorite this scenario` : `Favorite this scenario`}
        className={`ml-2 rounded-r-none ${plan.fav ? 'text-red-500 hover:text-red-500' : ''}`}
      >
        <HeartIcon className="h-4 w-4" />
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" aria-label="More options" className="rounded-l-none">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[230px]">
          <DropdownMenuLabel>Aktionen</DropdownMenuLabel>
          <DropdownMenuGroup>
            <DropdownMenuItem onSelect={() => handleCopyPlan(plan.id)}>
              <CopyIcon className="mr-2 h-4 w-4" />
              Kopieren
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={() => handleExportPlan(plan, timelineData)}>
              <DownloadIcon className="mr-2 h-4 w-4" />
              Exportieren (CSV)
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={() => handleViewInGoogleMaps()}>
              <MapIcon className="mr-2 h-4 w-4" />
              Route auf Google Maps
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => handleRemovePlan(plan.id)} className="text-red-600">
              <TrashIcon className="mr-2 h-4 w-4" />
              Löschen
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

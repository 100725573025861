import React from 'react';
import { useTour } from '../context/TourContext';
import { Scenario, ScenarioDateData } from '../types';
import { Button } from './ui/button';
import { Trash as TrashIcon, CalendarX as CalendarXIcon } from 'lucide-react';
import { ScenarioChangeCity } from './ScenarioChangeCity';
import { handleRemoveCityFromPlan } from '../lib/tourHelpers';

export function ScenarioTimeline({ plan, timelineData }: { plan: Scenario, timelineData: { [key: string]: ScenarioDateData } }) {
  const { state, dispatch } = useTour();

  return (
    <div className='border-b timeline'>
      {timelineData && Object.entries(timelineData)
      .filter(([dateKey, { date }]) => date !== undefined) // Filter out entries with undefined dates
      .map(([dateKey, { date, title, city, distance, start, option, optionClass }]) => {
        const isDayOff = !city;

        return (
          <div key={dateKey} id={dateKey} className={`tour-date-item flex transition-colors hover:bg-muted/50 px-5 ${isDayOff ? 'text-muted-foreground' : ''}`}>

            <div className={`tour-date-item-meta w-2/5 text-right py-3 pe-6 relative ${distance > state.maxDistancePerDay ? 'border-warning' : ''} ${distance === 0 ? 'border-dotted' : ''}`}>
              {distance > 0 && (
                <div className={`timeline-distance text-xs pe-5 ${distance > state.maxDistancePerDay ? 'text-yellow-600' : 'text-gray-600'}`}>
                  {distance} km
                </div>
              )}
              {!start && (<div>{date.toLocaleDateString('de-DE', { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' })}</div>)}
            </div>
            <div className="w-3/5 py-3 ps-5 relative justify-between items-center">
              <div>
                {isDayOff ? (
                  <div className="tour-date-item-data dot" ><CalendarXIcon className="h-4 w-4 inline me-2 -translate-y-0.5" />Day off</div>
                ) : (
                  <div className={`tour-date-item-data dot ${'dot-' + optionClass[0]}`}>
                    <div>{title} {state.countries.length > 1 && `(${city.country_code})`}</div>
                    <div className="text-xs text-gray-600">
                      {option}
                    </div>
                  </div>
                )}
              </div>
              <div className="tour-date-item-options absolute transition flex items-center">
                {!isDayOff && !start && (
                  <Button className="px-3 w-10" variant="outline" onClick={() => handleRemoveCityFromPlan(dateKey, plan, dispatch)} aria-label={`Remove ${city.city}`}>
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                )}
                {!start && (
                  <ScenarioChangeCity
                    possibleCities={state.cities.map((c) => c)}
                    date={date}
                    planId={plan.id}
                  />
              )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { postTourData, checkJobStatus, fetchJobResults, fetchCities } from '../services/apiService';
import { useTour } from '../context/TourContext';
import { City } from '@/types';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from "./ui/input";
import { Separator } from "./ui/separator";
import { Label } from "./ui/label";
import { useToast } from "./ui/use-toast"
import { Dices as DicesIcon } from 'lucide-react';

import { TourRange } from './TourRange';
import { TourCountries } from './TourCountries';
import { TourDateTable } from './TourDateTable';
import { TourDayOffOptions } from './TourDayOffOptions';
import { TourVariables } from './TourVariables';
import { TourAddCity } from './TourAddCity';
import { ScenariosOverview } from './ScenariosOverview';
import { normalizeDate } from '../lib/utils';
import ExportButton from './ExportButton'; 
import ImportButton from './ImportButton'; 


export function TourForm() {
  const { state, dispatch } = useTour();
  const { toast } = useToast()
  const [possibleCities, setPossibleCities] = useState<City[]>([]);

  useEffect(() => {
    const loadCities = async () => {
      const fetchedCities = await fetchCities(state.countries.map(country => country.country_code));
      setPossibleCities(fetchedCities);
    };

    loadCities();
  }, [state.countries]);

  const handleSubmit = async () => {
    dispatch({ type: 'SET_STATUS', payload: 'loading' });

    const tourData = {
      start_date: state.dateRange.start ? normalizeDate(state.dateRange.start).toISOString().slice(0, 10) : null,
      end_date: state.dateRange.end ? normalizeDate(state.dateRange.end).toISOString().slice(0, 10) : null,
      start_city: state.startCity?.city_qid,
      end_city: null,
      dates_data: state.cities.reduce((acc, city) => ({
        ...acc,
        [city.city.city_qid]: {
          date_fixed: city.venue_dates.flatMap(venue => venue.date_fixed).map(date => normalizeDate(new Date(date)).toISOString().slice(0, 10)),
          date_first_option: city.venue_dates.flatMap(venue => venue.date_first_option).map(date => normalizeDate(new Date(date)).toISOString().slice(0, 10)),
          date_second_option: city.venue_dates.flatMap(venue => venue.date_second_option).map(date => normalizeDate(new Date(date)).toISOString().slice(0, 10)),
          date_third_option: city.venue_dates.flatMap(venue => venue.date_third_option).map(date => normalizeDate(new Date(date)).toISOString().slice(0, 10)),
          iterations: city.iterations,
          priority: city.priority || false
        }
      }), {}),
      day_off_fixed: state.days_off_fixed.map(date => normalizeDate(new Date(date)).toISOString().slice(0, 10)),
      day_off_preferences: state.days_off_preferred.map(date => normalizeDate(new Date(date)).toISOString().slice(0, 10)),
      max_distance_per_day: state.maxDistancePerDay,
      max_tour_days_in_row: state.maxConsecutiveDays,
      countries: state.countries.map(country => country.country_code)
    };

    try {
      const responsePost = await postTourData(tourData);
      console.log('API response:', responsePost);
  
      // Start timeout timer
      const timeout = setTimeout(() => {
        dispatch({ type: 'SET_STATUS', payload: 'timeout' });
        toast({
          variant: "destructive",
          title: "Timeout",
          description: "Leider dauert die Antwort des Servers zu lange - probiere es gerne erneut.",
        });
      }, 180000); // 3 minutes
  
      await pollJobStatus(responsePost.job_id, timeout);
    } catch (error) {
      console.error('Failed to submit tour data:', error);
    }
  };
  
  const pollJobStatus = async (jobId: string, timeout: NodeJS.Timeout) => {
    let jobStatus = await checkJobStatus(jobId);
    console.log('API status:', jobStatus);
  
    while (jobStatus.status === 'queued' || jobStatus.status === 'started') {
      dispatch({ type: 'SET_STATUS', payload: 'loading' });
      await new Promise(resolve => setTimeout(resolve, 1000));
      jobStatus = await checkJobStatus(jobId);
      console.log('API status:', jobStatus);
    }

    const mapQidToCity = (city_qid: string) => {
      const cityDetails = possibleCities.find(city => city.city_qid === city_qid);
      if (!cityDetails) {
        throw new Error(`No city found for QID: ${city_qid}`);
      }
      return cityDetails;
    };

    const createScenarioFromResult = (result: any) => {
      const { id, plan, issues, info, creation } = result;

      const mappedPlan = plan.map((entry: any) => ({
        date: new Date(entry.date),
        city: mapQidToCity(entry.city_qid)
      }));

      return {
        id,
        plans: mappedPlan,
        issues,
        info,
        creation: new Date(creation),
        fav: false  // default value for fav
      };
    };

    if (jobStatus.status === 'finished') {
      dispatch({ type: 'SET_STATUS', payload: 'ready' });
      toast({
        title: "Gut gewürfelt!",
        description: "Ein Tour-Plan wurde erfolgreich erstellt.",
      })
      const fetched_result = await fetchJobResults(jobId);
      try {
        const tourPlan = createScenarioFromResult(fetched_result);
        dispatch({ type: 'ADD_SCENARIO', payload: tourPlan });
      } catch (error) {
        console.error('Failed to create tour plan:', error);
        toast({
          variant: "destructive",
          title: "Error - etwas ist schiefgegangen!",
          description: "Leider sind die Würfel falsch schief gefallen.",
        })
      }
    } else if (jobStatus.status === 'error') {
      dispatch({ type: 'SET_STATUS', payload: 'error' });
      toast({
        variant: "destructive",
        title: "Error - etwas ist schiefgegangen!",
        description: "Leider sind die Würfel falsch schief gefallen auf dem Server.",
      })
    } else if (jobStatus.status === 'timeout') {
      dispatch({ type: 'SET_STATUS', payload: 'timeout' });
      toast({
        variant: "destructive",
        title: "Timeout",
        description: "Leider dauert die Antwort des Server zu lange - probiere es gerne erneut.",
      })
    } else if (jobStatus.status === 'offline') {
      dispatch({ type: 'SET_STATUS', payload: 'offline' });
      toast({
        variant: "destructive",
        title: "Offline",
        description: "Offenbar hast Du keine Internetverbindung, bitte verbinde dich erneut.",
      })
    }
  
    clearTimeout(timeout); // Clear timeout if job finishes

  };

  const handleProjectTitleChange = (value: string) => {
    dispatch({ type: 'SET_PROJECT_TITLE', payload: value });
  };

  return (
    <div className="w-full">
      <Card>
        <CardHeader>
          <CardTitle>Rahmendaten</CardTitle>
          <CardDescription>Dauer der Tour und andere Basics</CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4 grid-cols-1 md:grid-cols-4">
          <div className="w-full">
            <Label htmlFor="project_title">Projekt/Act</Label>
            <Input type="string" className="mt-2" id="project_title" value={state.projectTitle.toString()}
                   onChange={(e) => handleProjectTitleChange(e.target.value)} />
          </div>

          <div>
            <Label htmlFor="TourRange">Tour-Dauer</Label>
            <TourRange />
          </div>

          <div>
            <Label htmlFor="TourCountries">Länder</Label>
            <TourCountries />
          </div>

          <div className="w-full">
            <Label htmlFor="max_distance_per_day">Ausgangsort</Label>
            <div>
              <TourAddCity possible_cities={possibleCities} usageType="SET_START_CITY"/>
            </div>
          </div>

        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Städte & Verfügbarkeiten</CardTitle>
          <CardDescription>Welche Städte können wann angefahren werden</CardDescription>
        </CardHeader>
        <CardContent>
          <TourDateTable />
          <div className="w-full flex justify-between items-center">
            <TourAddCity possible_cities={possibleCities} usageType="ADD_CITY" />
            <div>
              <ImportButton possible_cities={possibleCities} />
              <ExportButton />
            </div>

          </div>
          <Separator className="mt-5"/>
          <TourDayOffOptions className="mt-6" />

        </CardContent>
      </Card>


      <Card>
        <CardHeader>
          <CardTitle>Variablen</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent >
          <TourVariables className="mt-4" />
        </CardContent>
      </Card>

      {state.status !== "loading" && (
      <Button onClick={handleSubmit} className="w-full">
        Tour würfeln <DicesIcon className="h-6 w-6 inline ms-2" />
      </Button>
      )} {state.status === "loading" && (
        <Button onClick={handleSubmit} className="w-full opacity-50 cursor-not-allowed">
          Tour wird gewürfelt (bis zu 1 Minute)<DicesIcon className="h-6 w-6 inline ms-2 animate-spin" />
        </Button>
        )}
      <Separator className="mt-5"/>
      
      <ScenariosOverview />
    </div>
  );
}

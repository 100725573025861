import { TourState, Scenario, TourCity, City, ScenarioDateData, ScenarioData } from '../types';

export const getCityName = (cityQid: string | null, state: TourState, include_country_code?: boolean): string => {
  if (!cityQid) return 'Day off';
  const city = state.cities.find(city => city.city.city_qid === cityQid);
  if (city) {
    if (state.countries.length > 1 && include_country_code) {
      return `${city.city} (${city.city.country_code})`;
    }
    return city.city.city;
  }
  return 'City not found';
};

export const deleteScenario = (id: string, dispatch: React.Dispatch<any>) => {
  dispatch({ type: 'REMOVE_SCENARIO', id });
};

export const updateScenario = (plan: Scenario, dispatch: React.Dispatch<any>) => {
  dispatch({ type: 'UPDATE_SCENARIO', payload: plan });
};

export const handleRemoveCityFromPlan = (dateKey: string, plan: Scenario, dispatch: React.Dispatch<any>) => {
  const updatedPlans = plan.plans.filter(p => new Date(p.date).toISOString().slice(0, 10) !== dateKey);
  dispatch({ type: 'UPDATE_SCENARIO', payload: { ...plan, plans: updatedPlans } });
};

export const getDistance = (previousCityQid: string | undefined, currentCityQid: string | undefined, state: TourState): number | undefined => {
  if (previousCityQid === undefined || currentCityQid === undefined) {
    return undefined;
  }

  const distance = state.distanceMatrix[previousCityQid]?.[currentCityQid];
  return distance !== undefined ? distance : 0;
}

export const generateScenarioDateData = (plan: Scenario, state: TourState): ScenarioData => {
  const data: { [key: string]: ScenarioDateData } = {};
  const dateRange = generateDateRange(new Date(state.dateRange.start!), new Date(state.dateRange.end!), state.startCity);
  const daysOffFixedFormatted = state.days_off_fixed.map((d) => {
    // Ensure d is a Date object
    const date = typeof d === 'string' ? new Date(d) : d;
    return date.toISOString().slice(0, 10);
  });  
  let total_distance: number = 0;
  let issues: string = '';

  const isDateInRange = (date: Date, startDate: Date, endDate: Date) => {
    return date >= startDate && date <= endDate;
  };

  // Add range dates
  let previousDayIsDayOff = true
  let previousCityQid: string | null = null;

  dateRange.forEach(date => {
    const dateKey = formatDateKey(date);
    let cityDetails: TourCity | undefined = undefined;
    let city: City | null = null;
    let title: string = "Day Off"
    let start = false
    let cityQid = ""
    let distance = 0
    
    // Check if not start/end city
    if (isDateInRange(date, new Date(state.dateRange.start!), new Date(state.dateRange.end!))) {
      const planItem = plan.plans.find(p => formatDateKey(new Date(p.date)) === dateKey);

      // Check if day off
      if(planItem) {
        cityQid = planItem.city.city_qid;
        title =  planItem.city.city
        city = planItem.city
        cityDetails = state.cities.find((c) => c.city.city_qid === cityQid);
      } 

    } else {
      start = true;
      if (state.startCity) {
        cityQid = state.startCity.city_qid != null ? state.startCity.city_qid : "";
        cityDetails = state.cities.find((c) => c.city.city_qid === cityQid);
        city = cityDetails ? cityDetails.city : null;
        if (city) {
          title = city.city
        }
      }
    }
    
    // Get distance
    if (cityQid) {
      if (previousCityQid) {
        distance = state.distanceMatrix[previousCityQid]?.[cityQid] || 0;
        total_distance += distance;
      }

      // Set for next iteration
      previousCityQid = cityQid;
    }

    // Get meta info
    const optionCode = determineOptionCode(dateKey, cityQid, cityDetails, daysOffFixedFormatted, start, new Date(state.dateRange.end!) <= date)
    const option = determineOption(optionCode, date, cityDetails)
    const cssClasses = determineClasses(optionCode, state.maxDistancePerDay, distance, previousDayIsDayOff)
    
    data[dateKey] = { 
      date, 
      city: city, 
      title: title,
      distance: distance, 
      start: start, 
      option: option, 
      optionCode: optionCode,
      optionClass: cssClasses, 
    };

    // Set for next iteration
    previousDayIsDayOff = [6, 7, 16].includes(optionCode);
  });

  /** Collecting issues, for example, cities with missing distances or other data inconsistencies
  / - Missing distances
  / - Distances too long
  / - Fixed date but not in the timeline at that date
  * for (const key of Object.keys(data)) {
    if (data[key].city.city_qid && !state.distanceMatrix[data[key].city_qid!]) {
      issues += `Missing distance data for city ${data[key].cityQid} on ${data[key].date.toDateString()}. `;
    }
      }
  */ 
  

  return { timeline: data, total_distance, issues };
};



const generateDateRange = (startDate: Date, endDate: Date, start: City | null) => {
  const dates = [];
  let currentDate: Date = startDate
  let stopDate: Date = endDate

  if(start) {
    currentDate.setDate(startDate.getDate() - 1);
    stopDate.setDate(endDate.getDate() + 1);
  } 

  while (currentDate <= stopDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const formatDateKey = (date: Date) => {
  return date.toISOString().slice(0, 10);
};

const determineOptionCode = (dateString: string, cityQid: string, cityDetails: TourCity | undefined, daysOffFixedFormatted: string[], start: Boolean, end: Boolean) => {
  /** Option code
   * 0 - not set
   * 1 - 1. Option
   * 2 - 2. Option
   * 3 - 3. Option
   * 4 - Fixed Option
   * 5 - Not an option
   * 6 - Day off
   * 7 - Start city
   * 8 - End city
   * 9 - City not part of the plan anymore
   * 10 
   * 11 - Fixed Day off & 1. Option
   * 12 - Fixed Day off & 2. Option
   * 13 - Fixed Day off & 3. Option
   * 14 - Fixed Day off & Fixed Option
   * 15 - Fixed Day off & Not an option
   * 16 - Fixed Day off
   * 19 - Fixed Day off & City not part of the plan anymore
   */

  const optionCodeCheckFixedDayOff = (optionCode: number,dateString: string, daysOffFixedFormatted: string[]) => {
    if (daysOffFixedFormatted.includes(dateString)) {
      optionCode += 10;
    }
    return optionCode;
  };

  // If no city details, mark as day off
  if (!cityDetails) {
    if (!start) {
      // Day off or city not in the plan anymore
      if(cityQid) {
        return optionCodeCheckFixedDayOff(9, dateString, daysOffFixedFormatted);
      } else {
        return optionCodeCheckFixedDayOff(6, dateString, daysOffFixedFormatted);
      }
    } else {
      // Wether end or start
      if (!end) {
        return optionCodeCheckFixedDayOff(7, dateString, daysOffFixedFormatted);
      } else {
        return optionCodeCheckFixedDayOff(8, dateString, daysOffFixedFormatted);
      }
    }
  } else {
    // Wether end or start
    if (end) {
      return optionCodeCheckFixedDayOff(7, dateString, daysOffFixedFormatted);
    } 
    if (start) {
      return optionCodeCheckFixedDayOff(8, dateString, daysOffFixedFormatted);
    }
  }

  // Initialize option code as not an option
  let optionCode = 5;

  // Flatten and parse dates from venues
  const dateGroups = {
    fixed: new Set<string>(),
    firstOption: new Set<string>(),
    secondOption: new Set<string>(),
    thirdOption: new Set<string>(),
  };

  cityDetails.venue_dates.forEach((venue) => {
    venue.date_fixed.forEach((d) =>
      dateGroups.fixed.add(new Date(d).toISOString().slice(0, 10))
    );
    venue.date_first_option.forEach((d) =>
      dateGroups.firstOption.add(new Date(d).toISOString().slice(0, 10))
    );
    venue.date_second_option.forEach((d) =>
      dateGroups.secondOption.add(new Date(d).toISOString().slice(0, 10))
    );
    venue.date_third_option.forEach((d) =>
      dateGroups.thirdOption.add(new Date(d).toISOString().slice(0, 10))
    );
  });

  // Determine the option code based on the date
  if (dateGroups.firstOption.has(dateString)) {
    optionCode = 1;
  } else if (dateGroups.secondOption.has(dateString)) {
    optionCode = 2;
  } else if (dateGroups.thirdOption.has(dateString)) {
    optionCode = 3;
  } else if (dateGroups.fixed.has(dateString)) {
    optionCode = 4;
  }

  // Check for fixed day off
  return optionCodeCheckFixedDayOff(optionCode, dateString, daysOffFixedFormatted);
};
const determineClasses = (optionCode: number, maxDistancePerDay: number, distance: number, previousDayIsDayOff: Boolean) => {
  // set distance class
  let distanceClass = ""
  if (previousDayIsDayOff) {
    distanceClass = distance <= maxDistancePerDay ? "" : "yellow";
  } else {
    distanceClass = distance <= maxDistancePerDay ? "" : "red";
  }

  // Set note class
  let noteClass = [5, 9, 11, 12, 13, 14, 15, 19].includes(optionCode) ? "red" : "";

  // Set dot Class
  let dotClass = ""
  switch(optionCode) {
    case 1: // 1. Option
      dotClass = 'green'
      break
    case 2: // 2. Option
      dotClass = 'yellow';
      break
    case 3: // 3. Option
      dotClass = 'yellow';
      break
    case 4: // Fixed Option
      dotClass = 'green';
      break
    case 5: // Not an option
      dotClass = 'red';
      break
    case 6: // Day off
      dotClass = 'grey';
      break
    case 7: // Start city
      dotClass = 'grey';
      break
    case 8: // End city
      dotClass = 'grey';
      break
    case 16: 
      dotClass = 'grey';
      break
    default:
      dotClass = 'red';
      break
  }

  return [dotClass, distanceClass, noteClass]
};

const determineOption = (optionCode: number, date: Date, cityDetails: TourCity | undefined) => {

  const getOptionStringVenue = (optionCode: number, optionStringInput: string, date: Date, cityDetails: TourCity | undefined) => {
    if (!cityDetails) {
      return optionStringInput;
    }

    const dateString = date.toISOString().slice(0, 10);
    const venues = cityDetails.venue_dates;

    // If there is only one venue, return the original option string
    if (venues.length === 1) {
      return optionStringInput;
    } else {
      let optionStringVenue = optionStringInput;

      // Check the relevant date array based on the optionCode
      venues.forEach((venue) => {
        let relevantDates: Date[] = [];
        switch (optionCode) {
          case 1:
            relevantDates = venue.date_first_option;
            break;
          case 2:
            relevantDates = venue.date_second_option;
            break;
          case 3:
            relevantDates = venue.date_third_option;
            break;
          case 4:
            relevantDates = venue.date_fixed;
            break;
        }

        // Check if the date is included in the relevant dates for the venue
        if (relevantDates.map((d) => new Date(d).toISOString().slice(0, 10)).includes(dateString)) {
          optionStringVenue = venues.length > 1 ? `${optionStringVenue} - ${venue.venue}` : `${optionStringVenue}`;
        }

      });
      return optionStringVenue

    }
  }

  let optionString = ""

  switch(optionCode) {
    case 1: // 1. Option
      optionString = '1. Option';
      return getOptionStringVenue(optionCode, optionString, date, cityDetails);
    case 2: // 2. Option
      optionString = '2. Option';
      return getOptionStringVenue(optionCode, optionString, date, cityDetails);
    case 3: // 3. Option
      optionString =  '3. Option';
      return getOptionStringVenue(optionCode, optionString, date, cityDetails);
    case 4: // Fixed Option
      optionString = 'Fixer Termin';
      return getOptionStringVenue(optionCode, optionString, date, cityDetails);
    case 5: // Not an option
      return 'Keine Option';
    case 6: // Day off
      return '';
    case 7: // Start city
      return 'Ausgangspunkt';
    case 8: // End city
      return 'Ausgangspunkt';
    case 9: // City not part of the plan anymore
      return 'Stadt nicht mehr Teil des Plans'
    case 11: // Fixed day off & 1. Option
      return '1. Option, aber fixer Day Off';
    case 12: // Fixed day off & 2. Option
      return '2. Option, aber fixer Day Off';
    case 13: // Fixed day off & 3. Option
      return '3. Option, aber fixer Day Off';
    case 14: // Fixed day off & Fixer Termin
      return 'Fester Termin, aber fixer Day Off';
    case 15: // Fixed day off & Keine Option
      return 'Keine Option, aber fixer Day Off';  
    case 16: // Fixed day off
      return 'Fester Day Off';
    case 19: // Fixed Day off & City not part of the plan anymore
      return 'Fester Day Off & Stadt nicht mehr Teil des Plans'

    default:
      return '';
  }
};


import { useState } from "react";
import { MapPin, GitCommit } from "lucide-react";
import { Command, CommandGroup, CommandEmpty, CommandInput, CommandItem } from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { ScrollArea } from "./ui/scroll-area";
import { Button } from "./ui/button";
import { useTour } from "@/context/TourContext";
import { City, TourCity, Scenario } from "@/types";
import { getDistance, formatDateKey } from '../lib/tourHelpers';


interface ScenarioChangeCityProps {
  possibleCities: TourCity[];
  date: Date;
  planId: string;
}

export function ScenarioChangeCity({ possibleCities, date, planId }: ScenarioChangeCityProps) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useTour();

  const handleSelectCity = (city_qid: string) => {
    const tourCity = possibleCities.find(c => c.city.city_qid === city_qid);
    if (tourCity) {
      const updatedPlans = state.plans.map(plan => {
        if (plan.id === planId) {
          // Find the existing plan entry for the given date
          const existingEntryIndex = plan.plans.findIndex(p => 
            p.date.toISOString().slice(0, 10) === date.toISOString().slice(0, 10)
          );

          let newPlans;
          const city = tourCity.city
          if (existingEntryIndex !== -1) {
            // Update the existing entry with the new city
            newPlans = [...plan.plans];
            newPlans[existingEntryIndex] = { date, city };
          } else {
            // Add a new entry for the date with the new city
            newPlans = [...plan.plans, { date, city }];
          }

          return { ...plan, plans: newPlans };
        }
        return plan;
      });
      
      const updatedPlan = updatedPlans.find(plan => plan.id === planId) as Scenario;
      if (updatedPlan) {
        dispatch({
          type: 'UPDATE_SCENARIO',
          payload: updatedPlan
        });
      }

      setOpen(false);
    }
  };

  const distanceString = (date: Date, city: TourCity) => {
    const plan = state.plans.find(plan => plan.id === planId) as Scenario;
    const dateKey = formatDateKey(date);
  
    // Sort the plan.plans by date to ensure correct ordering
    const sortedPlans = [...plan.plans].sort((a, b) => a.date.getTime() - b.date.getTime());
  
    // Find the closest previous and next concert dates
    let previousDateQid: string | undefined;
    let nextDateQid: string | undefined;
  
    for (let i = 0; i < sortedPlans.length; i++) {
      const planDateKey = formatDateKey(sortedPlans[i].date);
      if (planDateKey < dateKey) {
        previousDateQid = sortedPlans[i].city.city_qid;
      } else if (planDateKey > dateKey && !nextDateQid) {
        nextDateQid = sortedPlans[i].city.city_qid;
        break;
      }
    }
  
    // Get distances
    const prevDistance = previousDateQid ? getDistance(previousDateQid, city.city.city_qid, state) : undefined;
    const nextDistance = nextDateQid ? getDistance(city.city.city_qid, nextDateQid, state) : undefined;
  
    return [
      prevDistance !== undefined ? prevDistance : 0,
      nextDistance !== undefined ? nextDistance : 0,
    ];
  }
  
  

  const determineOptionClass = (date: Date, city: City) => {
    const cityDetails = state.cities.find(c => c.city.city_qid === city.city_qid);
    if (!cityDetails) return 'dot-red'; // Default to 'dot-red' if city details are not found

    const dateString = date.toISOString().slice(0, 10);
    const venues = cityDetails.venue_dates;

    let optionClass = 'dot-red';
    venues.forEach((venue) => {
      const fixedDates = venue.date_fixed.map((d) => d.toISOString().slice(0, 10));
      const options = [
        venue.date_first_option,
        venue.date_second_option,
        venue.date_third_option,
      ];

      if (fixedDates.includes(dateString)) {
        optionClass = 'dot-green';
      } else {
        const optionIndex = options.findIndex((opts) =>
          opts.map((d) => d.toISOString().slice(0, 10)).includes(dateString)
        );
        if (optionIndex === 0) {
          optionClass = 'dot-green';
        } else if (optionIndex === 1) {
          optionClass = 'dot-yellow';
        }
      }
    });

    return optionClass;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="px-2 w-10">
          <MapPin className="w-5 h-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command className="w-full">
          <CommandInput placeholder="Stadt suchen" />
          <CommandEmpty>Stadt nicht gefunden</CommandEmpty>
          <CommandGroup>
            <ScrollArea className="h-40">
            {possibleCities.map((city) => {
                const [prevDistance, nextDistance] = distanceString(date, city);
                return (

                <CommandItem
                  key={city.city.city_qid}
                  value={city.city.city}
                  onSelect={() => handleSelectCity(city.city.city_qid)}
                >
                  <div className={`ps-7 dot flex w-full justify-between ${determineOptionClass(date, city.city)}`}>
                    <div>{city.city.city} </div>
                    <div className="text-xs text-muted-foreground"><span className={`${prevDistance > state.maxDistancePerDay ? 'text-yellow' : ''}`}>{prevDistance}</span><GitCommit className="w-6 h-6 inline" /><span className={`${nextDistance > state.maxDistancePerDay ? 'text-yellow' : ''}`}>{nextDistance}</span></div>
                  </div>
                </CommandItem>
                );
              })}
            </ScrollArea>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

import React, { useRef } from 'react';
import { useTour } from '../context/TourContext';
import { Button } from './ui/button';
import { Upload as UploadIcon } from 'lucide-react';
import Papa from 'papaparse';
import { useToast } from "./ui/use-toast"
import { City, TourCity } from '../types';

interface ImportButtonProps {
  possible_cities: City[];
}

const ImportButton: React.FC<ImportButtonProps> = ({ possible_cities }) => {
  const { dispatch } = useTour();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast()


  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        try {
          parseCSV(text);
        } catch (error) {
          showErrorToast();
        }
      };
      reader.readAsText(file);
    }
  };

  const showErrorToast = () => {
    toast({
      variant: "destructive",
      title: "CSV konnte nicht geladen werden",
      description: "Die import-Funktion ist leider noch in einem frühen Stadium.",
    });
  };

  const showSuccessToast = () => {
    toast({
      title: "Erfolgreich Importiert",
      description: "Das Würfeln kann beginnen.",
    });
  };

  const parseCSV = (csvData: string) => {
    Papa.parse(csvData, {
      header: true,
      complete: (result: Papa.ParseResult<any>) => {
        const { data } = result;
        try {
          validateAndProcessData(data);
          showSuccessToast();
        } catch (error) {
          console.error(error);
          showErrorToast();
        }
      }
    });
  };

  const validateDate = (dateString: string) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const validateAndProcessData = (data: any[]) => {
    const headers = Object.keys(data[0]);
    const dateHeaders = headers.slice(5);

    const citiesMap: { [key: string]: TourCity } = {};

    data.forEach(row => {
      const cityKey = `${row.City}-${row.Country}`;
      if (!citiesMap[cityKey]) {
        const city = possible_cities.find(c => c.city === row.City && c.country_code === row.Country);
        if (city) {
          citiesMap[cityKey] = {
            city: {
              city: city.city,
              city_qid: city.city_qid,
              country_code: city.country_code,
              latitude: city.latitude,
              longitude: city.longitude,
            },
            iterations: parseInt(row.Iterations),
            priority: row.Priority === 'True',
            venue_dates: [],
          };
        }
      }
      if (citiesMap[cityKey]) {
        const venueDates = {
          venue_qid: row.Venue,
          venue: row.Venue,
          date_fixed: dateHeaders.filter(date => row[date] === 'confirmed' && validateDate(date)).map(date => new Date(date)),
          date_first_option: dateHeaders.filter(date => row[date] === '1. option' && validateDate(date)).map(date => new Date(date)),
          date_second_option: dateHeaders.filter(date => row[date] === '2. option' && validateDate(date)).map(date => new Date(date)),
          date_third_option: dateHeaders.filter(date => row[date] === '3. option' && validateDate(date)).map(date => new Date(date)),
        };
        citiesMap[cityKey].venue_dates.push(venueDates);
      }
    });

    const cities = Object.values(citiesMap);

    if (cities.length === 0) {
      throw new Error('No matching cities found in the CSV data.');
    }

    const validDateHeaders = dateHeaders.filter(validateDate);
    if (validDateHeaders.length === 0) {
      throw new Error('No valid date headers found in the CSV data.');
    }

    const startDate = new Date(validDateHeaders[0]);
    const endDate = new Date(validDateHeaders[validDateHeaders.length - 1]);

    // Dispatch actions to update context
    dispatch({ type: 'SET_PROJECT_TITLE', payload: 'Imported Project' });
    dispatch({ type: 'SET_DATE_RANGE', payload: { start: startDate, end: endDate } });
    cities.forEach(city => {
      dispatch({ type: 'ADD_CITY', payload: city });
    });
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
      <Button onClick={triggerFileInput} variant="outline" className="mt-2 mr-2">
        Importieren (CSV) <UploadIcon className="ms-2 w-5 h-5" />
      </Button>
    </>
  );
};

export default ImportButton;

import { useTour } from '../context/TourContext';
import { Scenario, ScenarioDateData } from '../types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from './ui/button';
import { Trash as TrashIcon } from 'lucide-react';
import { ScenarioChangeCity } from './ScenarioChangeCity';
import { getCityName, handleRemoveCityFromPlan } from '../lib/tourHelpers';

export function ScenarioTable({ plan, timelineData }: { plan: Scenario, timelineData: { [key: string]: ScenarioDateData } }) {
  const { state, dispatch } = useTour();

  if (!state.dateRange.start || !state.dateRange.end) {
    return <div>Tour-Dauer ist nicht definiert</div>;
  }

  return (
    <Table className='border-b'>
      <TableHeader>
        <TableRow>
          <TableHead className='pr-1'>Tag</TableHead>
          <TableHead className='pl-1'>Datum</TableHead>
          <TableHead>Ort</TableHead>
          <TableHead>Land</TableHead>
          <TableHead>Distanz</TableHead>
          <TableHead>Typ</TableHead>
          <TableHead className='sr-only'>Optionen</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
      {timelineData && Object.entries(timelineData)
      .filter(([dateKey, { date }]) => date !== undefined) // Filter out entries with undefined dates
      .map(([dateKey, { date, title, city, distance, start, option, optionClass, optionCode }]) => {
        const isDayOff = !city;

          return (
            <TableRow key={dateKey} className={`tour-date-row ${isDayOff ? "text-muted-foreground" : ""}`}>
              <TableCell className='pr-1'>{!start ? date.toLocaleDateString('de-DE', { weekday: 'short'}) : ""}</TableCell>
              <TableCell className='pl-1'>{!start ? date.toLocaleDateString('de-DE') : ""}</TableCell>
              <TableCell className={`dot ${'dot-' + optionClass[0]}`}>{title}</TableCell>
              <TableCell>{city?.country_code}</TableCell>
              <TableCell className={`text-${optionClass[1]}`}>{distance > 0 ? `${distance} km` : '-'}</TableCell>
              <TableCell className={`text-${optionClass[2]}`}>{option}</TableCell>
              <TableCell className="w-0 p-0 align-top">
                <div className='flex justify-end'>
                  <div className="tour-date-item-options absolute transition flex items-center mt-1.5 me-5">
                    {!isDayOff && !start && (
                        <Button className='px-3 w-10 me-1' variant="outline" onClick={() => handleRemoveCityFromPlan(dateKey, plan, dispatch)} aria-label={`Remove ${title}`}>
                          <TrashIcon className="h-4 w-4" />
                        </Button>
                      )}
                    {!start && (
                      <ScenarioChangeCity
                        possibleCities={state.cities.map((c) => c)}
                        date={date}
                        planId={plan.id}
                      />
                    )}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

import React from "react";
import { useTour } from '../context/TourContext';
import { addDays, format } from "date-fns";
import { de } from 'date-fns/locale';
import { Calendar as CalendarIcon } from "lucide-react";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Button } from './ui/button';
import { normalizeDate } from '../lib/utils';

interface TourDatePickerProps {
  selectedDates: Date[];
  onChange: (dates: Date[] | undefined) => void;
  id?: string;
  className?: string;
}

export function TourDatePicker({ selectedDates, onChange, id, className }: TourDatePickerProps) {
  const { state } = useTour();
  const [isVisible, setIsVisible] = React.useState(false);
  
  // Normalize and sort dates
  const sortedDates = selectedDates.map(normalizeDate).sort((a, b) => a.getTime() - b.getTime());

  // Create an array of JSX elements with <br /> between dates
  const formattedDateElements = sortedDates.map((date, index) => (
    <React.Fragment key={index}>
      {format(date, "dd.MM.yy | iii", { locale: de })}
      {index < sortedDates.length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button id={id} className={`text-left align-top h-auto w-full ${className}`} onClick={() => setIsVisible(true)} variant="outline">
          {sortedDates.length > 0 ? (
            formattedDateElements
          ) : (
            <div className="flex content-center"> 
              <CalendarIcon className="mr-2" />
              <span>Termine</span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="multiple"
          selected={sortedDates}
          defaultMonth={state.dateRange.start || addDays(new Date(), 180)}
          onSelect={(dates: Date[] | undefined) => {
            onChange(dates?.map(normalizeDate));
            setIsVisible(false);
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
import { useState, useEffect } from 'react';
import { useTour } from '../context/TourContext';
import { Scenario, ScenarioData } from '../types';
import { Card, CardContent, CardTitle } from './ui/card';
import { ScenarioTimeline } from './ScenarioTimeline';
import { ScenarioTable } from './ScenarioTable';
import { ScenarioMap } from './ScenarioMap';
import { ScenarioActions } from './ScenarioActions';
import { generateScenarioDateData } from '../lib/tourHelpers';

import { Sheet as SheetIcon, GitCommit as TimelineIcon, Heart as HeartIcon, List as ListIcon } from 'lucide-react';
import { RadioButtonGroup, RadioButtonGroupItem } from './ui/radio-button-group';

export function ScenariosOverview() {
  const { state } = useTour();
  const [view, setView] = useState('table');
  const [filter, setFilter] = useState('all'); // New state for filter
  const [scenarioData, setScenarioData] = useState<{ [planId: string]: ScenarioData }>({});
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (state.dateRange.start && state.dateRange.end) {
      setLoading(true); // Set loading to true when data is being fetched
      const data = state.plans.reduce((acc: { [planId: string]: ScenarioData }, plan: Scenario) => {
        acc[plan.id] = generateScenarioDateData(plan, state);
        return acc;
      }, {});
      setScenarioData(data);
      setLoading(false); // Set loading to false when data is fetched
    }
  }, [state]);

  useEffect(() => {}, [scenarioData]);

  const getExcludedCities = (plan: Scenario) => {
    const includedCityIds = plan.plans.map((p) => p.city.city_qid);
    const excludedCities = state.cities.filter((city) => !includedCityIds.includes(city.city.city_qid));
    return excludedCities.map((city) => city.city).join(', ');
  };

  const filteredPlans = state.plans.filter(plan => filter === 'all' || (filter === 'favourites' && plan.fav));

  return (
    <section >
      <CardTitle className="mt-5">Gewürfelte Touren</CardTitle>
      <div className="w-full mt-4">
        <RadioButtonGroup value={view} onValueChange={setView} className='me-4'>
          <RadioButtonGroupItem value="table" id="r2"><SheetIcon className="h-4 w-4 me-1" />Table</RadioButtonGroupItem>
          <RadioButtonGroupItem value="timeline" id="r1"><TimelineIcon className="h-4 w-4 rotate-90 me-1" />Timeline</RadioButtonGroupItem>
        </RadioButtonGroup>
        <RadioButtonGroup value={filter} onValueChange={setFilter}>
          <RadioButtonGroupItem value="all" id="r3"><ListIcon className="h-4 w-4 me-1" />Alle anzeigen</RadioButtonGroupItem>
          <RadioButtonGroupItem value="favourites" id="r4"><HeartIcon className="h-4 w-4 me-1" />Favoriten</RadioButtonGroupItem>
        </RadioButtonGroup>
      </div>
      {!state.dateRange.start || !state.dateRange.end ? (
        <div className='mt-5'>Tour-Dauer ist nicht definiert</div>
      ) : state.plans.length === 0 ? (
        <div className='mt-5'>Noch keine Szenarien gewürfelt</div>
      ) : filter === 'favourites' && filteredPlans.length === 0 ? (
        <div className='mt-5'>Keine Favoriten vorhanden</div>
      ) : loading ? ( // Show loading indicator while data is being fetched
        <div className='mt-5'>Loading...</div>
      ) : (
        <div className={`grid grid-cols-1 gap-4 ${view === 'timeline' ? 'md:grid-cols-3 ' : 'md:grid-cols-2'}`}>
          {filteredPlans.slice().reverse().map((plan, index) => {
            return (
              <Card key={index}>
                {scenarioData[plan.id] ? <ScenarioMap key={`${view}-${plan.id}`} timelineData={scenarioData[plan.id].timeline} className='rounded-t-lg'/> : null}

                {view === 'timeline' ? (
                  scenarioData[plan.id] ? <ScenarioTimeline plan={plan} timelineData={scenarioData[plan.id].timeline} /> : null
                ) : (
                  scenarioData[plan.id] ? <ScenarioTable plan={plan} timelineData={scenarioData[plan.id].timeline} /> : null
                )}
                <CardContent>
                  <div className="flex justify-between items-end mb-4">
                    <div>
                      <div className="text-sm text-muted-foreground mt-5">
                        Gesamtdistanz: {scenarioData[plan.id]?.total_distance} km <br />
                      </div>
                      {getExcludedCities(plan) && (
                        <div className="text-sm text-muted-foreground mt-2">
                          Anmerkung: {getExcludedCities(plan)} wurde(n) nicht in den Tourplan aufgenommen.
                        </div>
                      )}
                      <div className="text-sm text-muted-foreground mt-2">
                        Erstellt: {new Date(plan.creation).toLocaleString('de-DE')} <br />
                        Id: {plan.id}
                      </div>
                    </div>
                    {scenarioData[plan.id] ? <ScenarioActions plan={plan} timelineData={scenarioData[plan.id].timeline}/> : null}
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>
      )}
    </section>
  );
}

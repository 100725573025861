import { MapContainer, TileLayer, Polyline, Marker, Tooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import { ScenarioDateData } from '../types';
import { useTour } from '../context/TourContext';
import { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';

// Fix for leaflet's default icon not showing properly with Webpack
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// SVG template with color as a parameter
const svgTemplate = (color: string) => `
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z' fill='${color}'/>
    <circle cx='12' cy='10' r='3' fill="white"/>
  </svg>`;

const svgTemplateStart = (color: string) => `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-dot">
    <circle cx="12" cy="12" r="10"/>
    <circle cx="12" cy="12" r="1"/>
  </svg>`;

// Function to create an icon with the specified color
const createIcon = (color: string, start?: boolean) => {
  const svgString = !start ? svgTemplate(color) : svgTemplateStart(color);

  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(blob);

  if(!start) {
    return new L.Icon({
      iconUrl: url,
      shadowUrl: markerShadow,
      iconSize: [35, 35],
      iconAnchor: [17.5, 33],
      popupAnchor: [1, -25],
      shadowSize: [44, 35],
    });
  } else {
    return new L.Icon({
      iconUrl: url,
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      popupAnchor: [10, 10],
      shadowSize: [0, 1],
    });  
  }
};

const greenIcon = createIcon('hsl(142, 71%, 45%)');
const yellowIcon = createIcon('hsl(45, 93%, 47%)');
const redIcon = createIcon('hsl(0, 84%, 60%)');
const defaultIcon = createIcon('hsl(0, 0%, 57%)', true);

function FitBounds({ positions, paddingTopLeft, paddingBottomRight }: { positions: [number, number][], paddingTopLeft?: [number, number], paddingBottomRight?: [number, number] }) {
  const map = useMap();

  useEffect(() => {
    if (positions.length > 0) {
      const bounds = L.latLngBounds(positions);
      map.fitBounds(bounds, { paddingTopLeft: paddingTopLeft || [30, 10], paddingBottomRight: paddingBottomRight || [10, 10] }); // Adding default padding of 50px if not provided
    }
  }, [positions, map, paddingTopLeft, paddingBottomRight]);

  return null;
}

export function ScenarioMap({ timelineData, className }: { timelineData: { [key: string]: ScenarioDateData }, className?: string; }) {
  const { state } = useTour();

  const positions: [number, number][] = [];
  const cityData: { name: string; position: [number, number]; option: string; iconClass: string,  distanceClass: string, date: Date }[] = [];

  Object.values(timelineData).forEach(({ city, optionClass, option, date }) => {
    if (city && city.latitude !== null && city.longitude !== null) {
      const position: [number, number] = [city.latitude, city.longitude];
      const iconClass = optionClass[0]
      const distanceClass = optionClass[1]
      positions.push(position);
      cityData.push({ name: city.city, position, option, date, iconClass, distanceClass });
    }
  });

  if (positions.length === 0) {
    return <div>No valid cities to display on the map</div>;
  }

  const getIconByOptionClass = (optionClass: string): L.Icon => {
    switch (optionClass) {
      case 'green':
        return greenIcon;
      case 'yellow':
        return yellowIcon;
      case 'red':
        return redIcon;
      case 'grey':
        return defaultIcon;
      default:
        return defaultIcon;
    }
  };

  // Separate grey and non-grey markers
  const greyMarkers = cityData.filter(city => city.iconClass === 'grey');
  const nonGreyMarkers = cityData.filter(city => city.iconClass !== 'grey');

  return (
    <MapContainer className={`h-72 w-full ${className}`}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FitBounds positions={positions} paddingTopLeft={[15, 25]} paddingBottomRight={[15, 15]} />
      {cityData.map((city, index) => {
        if (index > 0) {
          const prevCity = cityData[index - 1];
          const polylineColor = city.distanceClass === '' ? 'white' : city.distanceClass;
          return (
            <Polyline key={`polyline-${index}`} positions={[prevCity.position, city.position]} pathOptions={{ color: polylineColor, dashArray: '0.1 4' }} />
          );
        }
        return null;
      })}
      {/* Render grey markers first */}
      {greyMarkers.map((city, index) => (
        <Marker key={`grey-${index}`} position={city.position} icon={getIconByOptionClass(city.iconClass)}>
          <Tooltip offset={[0, -19]}><b>{city.name}</b><br />{city.date? city.date.toLocaleDateString("de") + " - " : ""}{city.option}</Tooltip>
        </Marker>
      ))}
      {/* Render other markers after */}
      {nonGreyMarkers.map((city, index) => (
        <Marker key={`nongrey-${index}`} position={city.position} icon={getIconByOptionClass(city.iconClass)}>
          <Tooltip offset={[0, -19]}><b>{city.name}</b><br />{city.date? city.date.toLocaleDateString("de") + " - " : ""}{city.option}</Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
}

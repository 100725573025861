import React from 'react';
import { useTour } from '../context/TourContext';
import { saveAs } from 'file-saver';
import { Button } from './ui/button';
import { format } from 'date-fns';
import { normalizeDate } from '../lib/utils';
import { Download as DownloadIcon } from 'lucide-react';


const ExportButton: React.FC = () => {
  const { state } = useTour();

  const exportToCSV = () => {
    const { dateRange, cities } = state;

    if (!dateRange.start || !dateRange.end) {
      alert('Please set the tour date range.');
      return;
    }

    const dates: string[] = [];
    let currentDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);

    while (currentDate <= endDate) {
      dates.push(format(normalizeDate(currentDate), 'yyyy-MM-dd'));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const headers = ['City', 'Country', 'Venue', 'Iterations', 'Priority', ...dates];
    const rows = cities.flatMap(city => 
      city.venue_dates.length ? city.venue_dates.map(venue => ({
        city: city.city,
        country: city.city.country_code,
        venue: venue.venue,
        iterations: city.iterations,
        priority: city.priority ? 'True' : 'False',
        dates: dates.map(date => {
          if (venue.date_fixed.map(d => format(normalizeDate(new Date(d)), 'yyyy-MM-dd')).includes(date)) return 'confirmed';
          if (venue.date_first_option.map(d => format(normalizeDate(new Date(d)), 'yyyy-MM-dd')).includes(date)) return '1. option';
          if (venue.date_second_option.map(d => format(normalizeDate(new Date(d)), 'yyyy-MM-dd')).includes(date)) return '2. option';
          if (venue.date_third_option.map(d => format(normalizeDate(new Date(d)), 'yyyy-MM-dd')).includes(date)) return '3. option';
          return '';
        })
      })) : [{
        city: city.city,
        country: city.city.country_code,
        venue: '',
        iterations: city.iterations,
        priority: city.priority ? 'True' : 'False',
        dates: dates.map(() => '')
      }]
    );

    const csvContent = [
      headers.join(','),
      ...rows.map(row => [row.city, row.country, row.venue, row.iterations, row.priority, ...row.dates].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const projectTitle = state.projectTitle.replace(/\s+/g, '_');

    saveAs(blob, `${format(new Date(), 'yyMMdd')}_${projectTitle}-${format(new Date(dateRange.start), 'yyMM')}_export.csv`);
  };

  return (
    <Button onClick={exportToCSV} variant="outline" className='mt-2'>
      Exportieren (CSV) <DownloadIcon className='ms-2 w-5 h-5' />
    </Button>
  );
};

export default ExportButton;

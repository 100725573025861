// App.tsx
import React from 'react';
import { TourProvider } from './context/TourContext'; 
import { TourForm } from './components/TourForm'; 
import { Badge } from "@/components/ui/badge";
import { Dices as DicesIcon } from 'lucide-react';
import { Toaster } from "@/components/ui/toaster";
import 'leaflet/dist/leaflet.css';

function App() {
  return (
    <TourProvider>
      <section className='py-8 container bg-'>
        <div className='w-full'>
        <h1 className='text-4xl font-bold'><DicesIcon className="h-6 w-6 inline me-1" />tourdice<Badge className='align-middle ms-3'>Early BETA</Badge></h1>
        </div>
        <TourForm />
        <div className='w-full mt-5 text-right text-sm'>
          <p>Derzeit noch in einem frühen Beta-Stadium, Fehler können passieren</p>
          <p ><a href="https://paulramisch.de/impressum.html" >Impressum & Datenschutz</a></p>
        </div>
      </section>
      <Toaster />
    </TourProvider>
  );
}

export default App;

import React, { useState } from 'react';
import { Check, ChevronsUpDown } from "lucide-react";
import { Command, CommandGroup, CommandEmpty, CommandInput, CommandItem } from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { ScrollArea } from "./ui/scroll-area"
import { Button } from './ui/button';
import { useTour } from '../context/TourContext';
import { Country } from '../types';

// Define the list of possible countries
const possibleCountries = [
  { country_code: 'ad', country: 'Andorra' },
  { country_code: 'ba', country: 'Bosnia and Herzegovina' },
  { country_code: 'cy', country: 'Cyprus' },
  { country_code: 'es', country: 'Spain' },
  { country_code: 'gr', country: 'Greece' },
  { country_code: 'it', country: 'Italy' },
  { country_code: 'md', country: 'Moldova' },
  { country_code: 'no', country: 'Norway' },
  { country_code: 'se', country: 'Sweden' },
  { country_code: 'xk', country: 'Kosovo' },
  { country_code: 'al', country: 'Albania' },
  { country_code: 'be', country: 'Belgium' },
  { country_code: 'cz', country: 'Czech Republic' },
  { country_code: 'fi', country: 'Finland' },
  { country_code: 'hr', country: 'Croatia' },
  { country_code: 'kz', country: 'Kazakhstan' },
  { country_code: 'me', country: 'Montenegro' },
  { country_code: 'pl', country: 'Poland' },
  { country_code: 'si', country: 'Slovenia' },
  { country_code: 'am', country: 'Armenia' },
  { country_code: 'bg', country: 'Bulgaria' },
  { country_code: 'de', country: 'Germany' },
  { country_code: 'fr', country: 'France' },
  { country_code: 'hu', country: 'Hungary' },
  { country_code: 'lt', country: 'Lithuania' },
  { country_code: 'mk', country: 'North Macedonia' },
  { country_code: 'pt', country: 'Portugal' },
  { country_code: 'sk', country: 'Slovakia' },
  { country_code: 'at', country: 'Austria' },
  { country_code: 'by', country: 'Belarus' },
  { country_code: 'dk', country: 'Denmark' },
  { country_code: 'gb', country: 'United Kingdom' },
  { country_code: 'ie', country: 'Ireland' },
  { country_code: 'lu', country: 'Luxembourg' },
  { country_code: 'mt', country: 'Malta' },
  { country_code: 'ro', country: 'Romania' },
  { country_code: 'tr', country: 'Turkey' },
  { country_code: 'az', country: 'Azerbaijan' },
  { country_code: 'ch', country: 'Switzerland' },
  { country_code: 'ee', country: 'Estonia' },
  { country_code: 'ge', country: 'Georgia' },
  { country_code: 'is', country: 'Iceland' },
  { country_code: 'lv', country: 'Latvia' },
  { country_code: 'nl', country: 'Netherlands' },
  { country_code: 'rs', country: 'Serbia' },
  { country_code: 'ua', country: 'Ukraine' },
];

export function TourCountries() {
  const { state, dispatch } = useTour();
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  const handleAddCountry = (country_code: string) => {
    const country = possibleCountries.find(c => c.country_code === country_code);
    if (country) {
      dispatch({
        type: 'ADD_COUNTRY',
        payload: {
          country_code: country.country_code,
          country: country.country
        }
      });
      setSelectedCountry(null);
    }
  };

  const handleRemoveCountry = (country_code: string) => {
    dispatch({
      type: 'REMOVE_COUNTRY',
      country_code
    });
  };

  return (
    <div className="">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" role="combobox" aria-expanded={open} className="w-full max-w-md justify-between mt-2">
            {selectedCountry ? possibleCountries.find((c: Country) => c.country_code === selectedCountry)?.country : "Land hinzufügen"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full max-w-md p-0">
          <Command>
            <CommandInput placeholder="Land suchen" />
            <CommandEmpty>Land nicht gefunden</CommandEmpty>
            <CommandGroup>
              <ScrollArea className="h-40">
                {possibleCountries.map((country) => (
                  <CommandItem
                    key={country.country_code}
                    value={country.country}
                    onSelect={() => handleAddCountry(country.country_code)}
                  >
                    <Check className={selectedCountry === country.country_code ? "mr-2 h-4 w-4 opacity-100" : "mr-2 h-4 w-4 opacity-0"} />
                    {country.country}
                  </CommandItem>
                ))}
              </ScrollArea>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      <div className="flex flex-wrap gap-2 mt-2">
        {state.countries.map(country => (
          <span key={country.country_code} className="transition-all border inline-flex items-center pl-2 bg-secondary text-secondary-foreground hover:bg-secondary/80 text-sm h-8 rounded-sm border-solid cursor-default animate-fadeIn font-normal">
            {country.country}
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground py-1 px-3 h-full hover:bg-transparent"
              type="button"
              onClick={() => handleRemoveCountry(country.country_code)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
              </svg>
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}

import axios from 'axios';
import { parseCoordinates } from '../lib/apiUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log("Using API Base URL:", API_BASE_URL);


// Function to fetch and parse city data from multiple countries
export async function fetchCities(countryCodes) {
  try {
    // Create an array of promises for each API call for the given country codes
    const promises = countryCodes.map(code =>
      axios.get(`${API_BASE_URL}/data/${code}`, {
        responseType: 'json'
      })
    );

    // Wait for all API calls to resolve
    const responses = await Promise.all(promises);

    // Combine the data from all responses
    let allCities = [];
    responses.forEach((response, index) => {
      const country_code = countryCodes[index];
      const cities = response.data;
      const filteredCities = Object.keys(cities).map(key => {
        const { latitude, longitude } = parseCoordinates(cities[key].coordinates);
        return {
          city_qid: key,
          city: cities[key].city,
          country_code: country_code,
          population: cities[key].population,
          latitude: latitude,
          longitude: longitude
      }});
      allCities = allCities.concat(filteredCities); // Combine into one array
    });

    // Sort the combined list by population in descending order
    allCities.sort((a, b) => b.population - a.population);

    return allCities;
  } catch (error) {
    console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
    console.error('Error fetching city data:', error);
    return [];
  }
}

// Post tour planning data
export async function postTourData(tourData) {
  const response = await axios.post(`${API_BASE_URL}/plan-tour`, tourData);
  return response.data;
}

// Check job status
export async function checkJobStatus(jobId) {
  try {
    const response = await axios.get(`${API_BASE_URL}/jobs/status/${jobId}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw new Error('offline'); // Network error
    }
    throw error;
  }
}

// Fetch job results
export async function fetchJobResults(jobId) {
  try {
    const response = await axios.get(`${API_BASE_URL}/jobs/result/${jobId}`);
    const data = response.data;
    const modifiedData = {
      ...data,
      id: jobId
    };
    return modifiedData;
  } catch (error) {
    if (!error.response) {
      throw new Error('offline'); // Network error
    }
    throw error; // Re-throw the error after logging it
  }
}

// Utility to map city names to QIDs
export function mapCityNameToQID(cityName, cityData) {
  const city = cityData.find(city => city.name === cityName);
  return city ? city.qid : null;
}
